<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <FilterBlock @search="search" />
        <UploadData
            uploadUrl="/web/erp-supplier-item/import-data/source-type/USE_BATCH_NO"
            downloadUrl="/download-supplier-item-import-template/soure-type/USE_BATCH_NO"
            v-model:visible="showUploadDataModal"
            @uploadSuccess="getTabelData"
        />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button
                            type="primary"
                            v-perm="'btn:supplier-item-import-1'"
                            @click="showUploadDataModal = true"
                        >
                            导入
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import { apiSupplierlMaterialList } from "@/api";
import UploadData from "@/components/UploadData";
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
        UploadData,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "作业单号",
                dataIndex: "importNo",
            },
            {
                title: "行号",
                dataIndex: "lineNo",
            },
            {
                title: "采购订单号",
                dataIndex: "purchaseOrderCode",
            },
            {
                title: "物料名称",
                dataIndex: "itemName",
            },
            {
                title: "规格型号",
                dataIndex: "itemSpec",
            },
            {
                title: "供应商",
                dataIndex: "supplier",
            },
            {
                title: "数量",
                dataIndex: "count",
            },
            {
                title: "单位",
                dataIndex: "primaryUom",
            },
            {
                title: "批次号",
                dataIndex: "batchNo",
            },
            {
                title: "供应商物料批次条码",
                dataIndex: "barCode",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 80,
            },
        ];

        const state = reactive({
            detailId: null,
            showUploadDataModal: false,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiSupplierlMaterialList({
                pageNum,
                pageSize,
                queryParam: {
                    sourceType: "USE_BATCH_NO",
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                const { totalRecord, recordList } = res?.data;
                state.pagination.total = totalRecord;
                state.data = recordList;
            }
        };

        return {
            columns,
            ...toRefs(state),
            paginationChange,
            getTabelData,
            search,
        };
    },
});
</script>

<style lang="less" scoped>
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
</style>
